<template>
  <section>
    <el-form :inline="true" size="mini">
      <el-form-item>
        <el-cascader
          placeholder="所属单位"
          v-model="selOwner"
          :options="customerTree"
          :props="orgProps"
          clearable
          filterable
        />
      </el-form-item>
      <el-form-item>
        <el-cascader
          placeholder="区域"
          v-model="selArea"
          :options="areaTree"
          :props="areaProps"
          clearable
          filterable
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="getData">查询</el-button>
      </el-form-item>
    </el-form>
    <div v-loading="mapLoading">
      <table class="device-info">
        <thead>
          <tr>
            <!-- <th rowspan="2">监测点 {{locale&&locale.length}}</th>
            <th rowspan="2">监测设备 {{Dtotal}}</th>
            <th>昨日在线 {{daily.Dnormal+daily.Dexcess}}</th>
            <th>昨日离线 {{Dtotal-daily.Dnormal-daily.Dexcess}}</th>-->
            <!-- <th>昨日达标 {{detectorOn}}</th> -->
            <!-- <th>昨日超标 {{daily.Dexcess}}</th> -->
            <!-- <th rowspan="2">昨日排放排量 {{daily.Emissions}}KG</th> -->
            <!-- <th rowspan="2">昨日C排放量 {{CEmissions}}KG</th> -->
            <!-- <th rowspan="2">昨日PM2.5减排量 {{daily.Pm25}}KG</th>
            <th rowspan="2">昨日PM10减排量 {{daily.Pm10}}KG</th>
            <th rowspan="2">昨日油烟减排量 {{daily.RedEmissions}}KG</th>
            <th rowspan="2">昨日VOCS减排量 {{daily.RedVocs}}KG</th>-->
            <th rowspan="2">监控设备 {{Mtotal}}</th>
            <th>昨日在线 {{daily.onlineCount}}</th>
            <th>昨日离线 {{daily.offCount}}</th>
          </tr>
          <tr>
            <!-- <th>占比 {{Dtotal===0?0:((daily.Dnormal+daily.Dexcess)/Dtotal*100).toFixed(2)+'%'}}</th>
            <th>占比 {{Dtotal===0?0:((Dtotal-daily.Dnormal-daily.Dexcess)/Dtotal*100).toFixed(2)+'%'}}</th>-->
            <!-- <th>占比 {{(detectorOn/Dtotal*100).toFixed(2)+'%'}}</th> -->
            <!-- <th>占比 {{Dtotal===0?0:(daily.Dexcess/Dtotal*100).toFixed(2)+'%'}}</th> -->
            <template v-if="Mtotal===0">
              <th>占比 0.00%</th>
              <th>占比 0.00%</th>
            </template>
            <template v-else>
              <th>占比 {{(Number(daily.onPercent)*100).toFixed(2)+'%'}}</th>
              <th>占比 {{(Number(daily.offPercent)*100).toFixed(2)+'%'}}</th>
            </template>
          </tr>
        </thead>
      </table>
      <baidu-map
        class="bm-view"
        ak="770b4c07458f53896ff0abd948755e20"
        :scroll-wheel-zoom="true"
        :center="mapCenter"
        :zoom="mapZoom"
      >
        <!-- <bm-navigation anchor="BMAP_ANCHOR_BOTTOM_LEFT" /> -->
        <!-- animation="BMAP_ANIMATION_BOUNCE" lng:parseFloat(item.Lng),lat: parseFloat(item.Lat)-->
        <template v-for="(e, i) in data">
          <bm-marker
            :key="i"
            :position="{lng:e.Lng,lat:e.Lat}"
            @click="handlerWindowOpen(i)"
            :dragging="true"
            :icon="{url: getIcon(e.Status), size: {width: 28, height: 41}}"
          >
            <bm-info-window class="window_info_box" :show="e.Show" @close="handlerWindowClose(i)">
              <p>
                店铺名称:
                <span>{{getSinglePname(e.Owner)}}</span>
              </p>
              <p>
                联系电话:
                <span>{{e.Contact}}</span>
              </p>
              <h5>监测点信息</h5>
              <p>
                监测点名称:
                <span>{{e.Name}}</span>
              </p>
              <p>
                菜系:
                <span>{{e.Cuisine}}</span>
              </p>
              <p>
                地址:
                <span>{{e.Addr}}</span>
              </p>
              <!-- <div class="bg-purple"><span style="color:#909399">地址: </span><span>{{e.Name}}</span></div> -->
              <template v-if="e.MN">
                <!-- <p>组织结构代码: <span>{{e.Name}}</span></p> -->
                <div class="bg-purple">
                  <span style="color:#909399">{{e.Typ===2?'监测':'监控'}}设备:</span>
                  <span>{{e.MN}}</span>
                </div>
                <el-button
                  size="mini"
                  @click="handlePreview(e.Id)"
                  type="text"
                  icon="el-icon-picture"
                >查看现场照片</el-button>
                <!-- <el-button size="mini" @click='handleShowIntime(e.MN,e.Typ)' type="text" icon='el-icon-notebook-2'>查看每分钟采集数据</el-button> -->
                <!-- <el-button v-if='e.Typ===2' size="mini" @click='handleShowTenMin(e.MN,e.Typ)' type="text" icon='el-icon-s-data'>查看浓度</el-button> -->
              </template>
            </bm-info-window>
          </bm-marker>
        </template>
      </baidu-map>
      <el-dialog :visible.sync="showDlg" @close="handlerClose" width="85%">
        <!-- <figure style="background-color: rgb(175, 173, 173)"></figure> -->
        <el-form :inline="true" size="mini">
          <el-form-item>
            <el-select v-model="concentrationType" placeholder="请选择">
              <el-option :value="1" label="按十分钟"></el-option>
              <el-option :value="2" label="按天"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <!-- 按十分钟 -->
            <el-date-picker
              v-if="concentrationType === 1"
              @change="handleDateSel"
              v-model="selDate"
              type="date"
              value-format="timestamp"
              placeholder="日期选择"
            />

            <!-- 按天 -->
            <el-date-picker
              v-else-if="concentrationType === 2"
              type="daterange"
              :picker-options="pickerOptions"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            ></el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-download" @click="handleExport">导出</el-button>
          </el-form-item>
        </el-form>
        <template v-if="showTyp">
          <!-- <span slot="title"><strong>10min浓度曲线</strong></span> -->
          <span slot="title">
            <strong>浓度曲线</strong>
          </span>
          <div class="chart-line">
            <chart :options="tenMinOption" autoresize />
          </div>
        </template>
        <template v-else>
          <span slot="title">
            <strong>每分钟采集数据</strong>
          </span>
          <el-table
            :data="intimeData.content"
            size="mini"
            border
            highlight-current-row
            v-loading="loading"
            :max-height="clientHeight"
            style="width: 100%"
          >
            <el-table-column type="index" label="序号" align="center" width="55" />
            <el-table-column
              prop="acquit_at"
              :formatter="dateFormat"
              label="采集时间"
              width="142"
              align="center"
              header-align="center"
            />
            <el-table-column
              :formatter="fanFilterFormatter"
              label="风机状态"
              align="center"
              header-align="center"
            />
            <el-table-column
              :formatter="fanFilterFormatter"
              label="净化器状态"
              align="center"
              header-align="center"
            />
            <template v-if="device.Typ===2">
              <el-table-column
                prop="emissions_conc"
                label="油烟浓度(mg/m³)"
                align="center"
                header-align="center"
              />
              <el-table-column
                prop="granule_conc"
                label="颗粒物浓度(mg/m³)"
                align="center"
                header-align="center"
              />
              <el-table-column
                prop="hydrocarbon_conc"
                label="非甲烷总烃浓度(mg/m³)"
                align="center"
                header-align="center"
              />
              <!--
              <el-table-column prop='velocity' label='流速' align='center' header-align='center' />
              <el-table-column prop='temperature' label='温度' align='center' header-align='center' />
              <el-table-column prop='moisture' label='湿度' align='center' header-align='center' />-->
            </template>
            <template v-else>
              <el-table-column prop="poweroff" label="电源关闭指令" align="center" header-align="center" />
              <!-- <el-table-column prop='Flag' label='设备状态' align='center' header-align='center' /> -->
            </template>
          </el-table>
          <el-pagination
            small
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="filter.page"
            :page-sizes="filter.pageSizes"
            layout="total, sizes, prev, pager, next, jumper"
            :total="intimeData.total"
            style="display:inline-block;margin-left:15px"
          />
        </template>
      </el-dialog>
      <el-dialog :visible.sync="preview">
        <el-alert
          v-if="fileList.length===0"
          center
          show-icon
          :closable="false"
          style="margin-bottom: 8px"
          title="未上传现场照片"
          type="info"
        ></el-alert>
        <el-image
          v-else
          style="width:100%; margin:0 auto"
          v-for="(item, i) in fileList"
          :key="i"
          :src="item.url"
        >
        <div slot="error" class="image-slot">
          暂无图片
        </div>
        </el-image>
      </el-dialog>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
import {
  ifNull,
  dateFormater,
  loadData,
  export_json,
  join,
  getUserInfo,
  getSinglePname,
} from "@/util/index";
import conf from "@/config";
import BaiduMap from "vue-baidu-map/components/map/Map.vue"; // 局部注册
import BmMarker from "vue-baidu-map/components/overlays/Marker.vue";
import BmInfoWindow from "vue-baidu-map/components/overlays/InfoWindow.vue";
// import BmNavigation from 'vue-baidu-map/components/controls/Navigation.vue'
import _1 from "@/asset/img/mapOnline.png";
import _3 from "@/asset/img/mapOffline.png";
import _2 from "@/asset/img/mapAlert.png";
import _4 from "@/asset/img/mapClosed.png";
import _5 from "@/asset/img/locale.png";

import ECharts from "vue-echarts";
import "echarts/lib/chart/line";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/markLine";

export default {
  components: {
    BaiduMap,
    BmMarker,
    BmInfoWindow,
    chart: ECharts,
  },
  data() {
    return {
      isShowNotificationDialogData:{},
      concentrationType: 1, // 浓度类型：1按十分钟；2 按天
      pickerOptions: {
        // 查看浓度：按天
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      selDate: null,
      selOwner: null,
      selArea: null,
      data: [],
      now: null,
      Mtotal: 0,
      Dtotal: 0,
      userInfo: null,
      loading: true,
      mapLoading: true,
      daily: {},
      mapCenter: { lng: 120.306305, lat: 30.424877 },
      mapZoom: 14,
      showDlg: false,
      device: {},
      showTyp: null,
      preview: false,
      fileList: [],
      exportData: null,
      linkStatus: null,
      intimeData: {},
      sill: null,
      tenMinOption: {
        tooltip: {
          trigger: "axis",
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: [],
          boundaryGap: false,
          axisLabel: {
            interval: 17,
          },
        },
        yAxis: {
          type: "value",
          // min: 5,
          // data : [0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4,5],
          name: "油烟平均浓度mg/m³",
        },
        series: [
          {
            name: "油烟浓度",
            type: "line",
            data: [],
            itemStyle: {
              normal: {
                color: "#2ec7c9",
                lineStyle: {
                  type: "solid",
                  color: "#00A1FF",
                },
              },
            },
            markLine: {
              symbol: "none",
              itemStyle: {
                normal: {
                  lineStyle: {
                    type: "solid",
                    color: "red",
                  },
                  label: {
                    position: "start",
                    formatter: "{c} ",
                  },
                },
              },
              data: [
                {
                  name: "阈值",
                  yAxis: 1,
                },
              ],
            },
          },
        ],
      },
      filter: {
        page: 1,
        size: 20,
        pageSizes: [20, 30, 50],
      },
    };
  },
  computed: {
    ...mapState(["areaTree", "customerTree", "clientHeight", "locale"]),
    ...mapState({
      orgProps: (state) =>
        Object.assign({}, state.props, { label: "Org", checkStrictly: true }),
      areaProps: (state) =>
        Object.assign({}, state.props, { checkStrictly: true }),
    }),
  },
  created() {
    this.now = Date.parse(new Date()) / 1000;
    this.userInfo = getUserInfo().Info;
    loadData(this, () => {
      this.getData();
    });
  },
  methods: {
    getData() {
      // this.mapLoading = this.$loading({
      //   lock: true,
      //   text: '数据加载中，请稍候',
      //   spinner: 'el-icon-loading',
      //   background: 'rgba(0, 0, 0, 0.5)'
      // })
      if (!this.mapLoading) {
        this.mapLoading = true;
      }
      const param = {}
      if (this.selOwner && this.selOwner.length) {
        param.OwnerId = "/" + this.selOwner[this.selOwner.length - 1] + "/"
      }
      if (this.selArea) {
        param.AreaId = this.selArea[this.selArea.length - 1]
      }
      this.Mtotal = 0;
      this.Dtotal = 0;
      this.$post("admin/listMonitorDevice", param).then((res) => {
        this.daily.onlineCount = res.onlineCount;
        this.daily.offCount = res.offCount;
        this.daily.offPercent = res.offPercent;
        this.daily.onPercent = res.onPercent;

        this.data = res.device.map((v) => {
          return v.Locale;
        });
        res.device.forEach((e) => {
          if (e.Typ === 1) {
            this.Mtotal += 1;
          } else {
            this.Dtotal += 1;
          }
        });
        if (this.data.length !== 0) {
          this.mapCenter = { lng: this.data[0].Lng, lat: this.data[0].Lat };
        }
        this.mapLoading = false;
      });
    },
    getIcon(status) {
      switch (status) {
        case "NORMAL":
          return _1;
        case "EXCESS":
          return _2;
        case "DOWN":
          return _3;
        case "OFF":
          return _4;
        default:
          return _5;
      }
    },
    getSinglePname(pid) {
      return getSinglePname(this.customerTree, pid, "Org");
    },
    dateFormat(r, c) {
      return dateFormater(r[c.property], true);
    },
    fanFilterFormatter(r) {
      if (this.linkStatus === 1) {
        return "开";
      }
      return "-";
    },
    handlePreview(id) {
      this.fileList = [];
      const par = { Param: { id: id } };
      this.$post("admin/listLocale", par)
        .then((res) => {
          const urls = res.content[0].LocalePicS || []
          urls.forEach((item) => {
            this.fileList.push({ url: item });
          });
        })
        .catch(() => {});
      this.preview = true;
    },
    handleExport() {
      if (this.showTyp) {
        export_json(
          dateFormater(this.selDate / 1000, false) + "-" + this.mn,
          this.exportData,
          {
            采集时间: "采集时间",
            采集次数: "采集次数",
            油烟平均浓度汇总: "油烟平均浓度汇总",
          }
        );
      } else {
        this.$download("admin/exportIntimeData", {
          mn: this.device.MN,
          typ: this.device.Typ,
          at: this.selDate / 1000,
        });
      }
    },
    handleDateSel(v) {
      if (v !== null) {
        if (this.showTyp) {
          this.getTenMinData();
        } else {
          this.getIntimeData();
        }
      }
    },
    selsChange: function (sels) {
      this.sels = sels;
    },
    // 改变页容量
    handleSizeChange(size) {
      this.filter.size = size;
      this.getIntimeData();
    },
    // 翻页
    handleCurrentChange(page) {
      this.filter.page = page;
      this.getIntimeData();
    },
    getIntimeData() {
      this.loading = true;
      this.$post("admin/listIntimeData", {
        StartAt: (this.filter.page - 1) * this.filter.size,
        Size: this.filter.size,
        mn: this.device.MN,
        typ: this.device.Typ,
        at: this.selDate / 1000,
      }).then((res) => {
        this.loading = false;
        this.intimeData = res;
        if (res.total !== 0 && this.selDate === null) {
          this.selDate = new Date(
            new Date(res.content[0]["acquit_at"] * 1000).setHours(0, 0, 0, 0)
          ).getTime();
        }
      });
    },
    getTenMinData() {
      this.loading = true;
      this.$post("admin/listTenMinData", {
        mn: this.device.MN,
        at: this.selDate / 1000,
      }).then((res) => {
        this.loading = false;
        this.exportData = [];
        this.tenMinOption.series[0]["data"] = [];
        this.tenMinOption.series[0]["markLine"]["data"][0]["yAxis"] = this.sill;
        this.tenMinOption.xAxis["data"] = [];
        if (res && res.length !== 0) {
          if (this.selDate === null) {
            this.selDate = new Date(
              new Date(res[0]["acquit_at"] * 600 * 1000).setHours(0, 0, 0, 0)
            ).getTime();
          }
          let data;
          const t = new Date(this.selDate);
          t.setHours(0, 0, 0, 0);
          const endTime = Math.floor(t.getTime() / 600000) + 143;
          let at;
          for (var i = Math.floor(t.getTime() / 600000); i <= endTime; i++) {
            data = res.find((e) => e.acquit_at === i);
            at = dateFormater(i * 600, true, true);
            if (!data) {
              data = {
                emissions_conc: 0,
                granule_conc: 0,
                hydrocarbon_conc: 0,
                counter: 1,
              };
            } else {
              this.exportData.push({
                采集时间: at,
                采集次数: data.counter,
                油烟平均浓度汇总: data.emissions_conc,
              });
            }
            this.tenMinOption.series[0]["data"].push(
              (data.emissions_conc / data.counter).toFixed(2)
            );
            this.tenMinOption.xAxis["data"].push(at);
          }
          this.$forceUpdate();
        }
      });
    },
    handleShowIntime(mn, typ) {
      this.device = { MN: mn, Typ: typ };
      this.showTyp = false;
      this.showDlg = true;
      this.getIntimeData();
    },
    handleShowTenMin(mn, typ) {
      this.device = { MN: mn, Typ: typ };
      this.showTyp = true;
      this.showDlg = true;
      this.getTenMinData();
    },
    handlerWindowOpen(i) {
      this.data[i] = Object.assign(this.data[i], { Show: true });
      this.sill = this.data[i]["EmissionsSill"];
      this.linkStatus = this.data[i]["LinkStatus"];
      this.$forceUpdate();
    },
    handlerWindowClose(i) {
      // this.$set(this.selLocale[i], 'Show', false)
      // this.selLocale[i] = Object.assign(this.selLocale[i], { Show: false })
      // this.$set(this.selLocale, this.selLocale)
      this.data[i] = Object.assign(this.data[i], { Show: false });
      this.$forceUpdate();
    },
    handlerClose() {
      this.selDate = null;
      // this.getData()
    },
  },
};
</script>
<style lang="scss" scoped>
// 快速跳转 css
.quick_jump_box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-decoration: none;
    color: #333333;
    font-size: 12px;
    padding: 0 10px;

    i {
      font-size: 18px;
    }

    &:hover {
      color: #409eff;
    }
  }
}
.bm-view {
  width: 100%;
  height: 70vh;
  .BMap_bubble_content div {
    padding-top: 8px;
  }
  .bg-purple {
    // padding: 4px;
    // border-radius: 4px;
    font-size: 13px;
    min-width: 320px;
    // background: #E1EDF2;
  }
}

.window_info_box {
  p {
    margin: 0;
    font-size: 12px;
    color: #999999;

    span {
      color: #333333;
    }
  }

  h5 {
    font-size: 12px;
    // margin: 8px 0 5px;
    margin: 5px 0 0;
  }
}

.device-info {
  display: table;
}
</style>
